import React from 'react';
import { BoxIcon, Checked, UnChecked } from '../../assets/icons';
import { DISPLAY_TEXTS } from '../../constants';

export const BlpTiles = ({ tile, clickHandler, to, printMode, selectedBlp, setSelectedBlp }) => {
  const tileClass =
    Array.from(new Set(tile.SKU_Details.map((quant) => quant.SKU))).length > 1
      ? 'pink-shade'
      : 'blue-shade';
  const addToSelected = (blp) => {
    selectedBlp.map((id) => id.id).includes(blp)
      ? setSelectedBlp((pre) => pre.filter((BLP) => BLP.id !== blp))
      : setSelectedBlp((pre) => [...pre, { id: blp }]);
  };
  return (
    <div
      className="col-xl-2 col-lg-4 col-6 custom-col pt-2"
      onClick={() => (printMode ? addToSelected(tile.id) : clickHandler(to, { data: tile }))}>
      <div className="w-100 h-100 bg-white tiles">
        <div className={`w-100 d-flex align-items-center justify-content-center ${tileClass}`}>
          <span className="id-info ">
            {DISPLAY_TEXTS.BLP_TILES[0]}
            <span>{tile.BLP}</span>
          </span>
          {printMode && (
            <div className="multi-selection ">
              {selectedBlp.map((id) => id.id).includes(tile.id) ? <Checked /> : <UnChecked />}
            </div>
          )}
        </div>
        <div className="w-100 d-flex align-items-center justify-content-center main-content-box">
          <div className="img-box">
            <BoxIcon />
          </div>
          <div className="sku-number flex-column d-flex align-items-start justify-content-start">
            <h6 className="m-0">
              {DISPLAY_TEXTS.BLP_TILES[1]}
              <span>{tile.SKU}</span>
            </h6>
            <p className="m-0">
              {DISPLAY_TEXTS.BLP_TILES[2]} {tile.Batch}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
