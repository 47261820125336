import React, { useEffect, useState } from 'react';
import { BUTTON, POPUP_TYPE, REPORTS_COLUMN, ROUTES, TITLES } from '../../../constants';
import { SearchBarReport, Table, TitleBar } from '../../common';
import { useDispatch } from 'react-redux';
import { setLoaderVisibility } from '../../../redux';
import { popup } from '../../../utils';
import { vendorReport } from '../../../service/report';
import { Button } from '../../shared';
import { reportDataCompiler } from '../../../utils/dataCompiler/wipReportCompiler';

export const ReportsContent = () => {
  const dispatch = useDispatch();
  const [searchTerm1, setSearchTerm1] = useState('');
  const [searchTerm2, setSearchTerm2] = useState('');
  const [searchTerm3, setSearchTerm3] = useState('');
  const [search, setSearch] = useState(false);
  const [reportData, setReportData] = useState([]);

  const getReport = () => {
    let data = {};
    if (searchTerm1.length > 0) {
      data.lot_name = searchTerm1;
      setSearchTerm1('');
    }
    if (searchTerm2.length > 0) {
      data.default_code = searchTerm2;
      setSearchTerm2('');
    }
    if (searchTerm3.length > 0) {
      data.po_name = searchTerm3;
      setSearchTerm3('');
    }
    vendorReport({ data })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response?.data?.status) 
          popup(POPUP_TYPE.ERR, response.data.status);
        else
          setReportData(response.data);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  };

  useEffect(() => {
    if (search) {
      getReport();
      setSearch(false);
    }
  }, [search]);

  const handleSearch = () => {
    if (searchTerm1 || searchTerm2) {
      setSearch(true);
    }
  };
  
  const handleClearFilter = () => {
    setReportData([]);
  };

  return (
    <>
      <TitleBar title={[{ text: TITLES.REPORTS, route: ROUTES.REPORTS }]} />
      <div className="d-flex justify-content-start gap-2 mb-2">
        <SearchBarReport
          setSearchTerm={setSearchTerm1}
          searchTerm={searchTerm1}
          placeholder={'Lot Name'}
        />
        <SearchBarReport
          setSearchTerm={setSearchTerm2}
          searchTerm={searchTerm2}
          placeholder={'SKU Name'}
        />
        <SearchBarReport
          setSearchTerm={setSearchTerm3}
          searchTerm={searchTerm3}
          placeholder={'PO Name'}
        />
        <Button
          clickHandler={handleSearch}
          className={`search-btn ${!searchTerm1.length > 0 && !searchTerm2.length > 0 ? 'disabled' : ''} d-flex align-items-center justify-content-center px-3`}
          disabled={!searchTerm1.length > 0 && !searchTerm2.length > 0}>
          {BUTTON.SEARCH}
        </Button>
        <Button
          clickHandler={handleClearFilter}
          className={`search-btn ${reportData?.length===0 ? 'disabled' : ''} d-flex align-items-center justify-content-center px-3`}
          disabled={reportData?.length===0}>
          {BUTTON.CLEAR_FILTERS}
        </Button>
      </div>
      {reportData.length ? (
        <Table
          columns={REPORTS_COLUMN}
          initialData={reportData}
          PAGE_SIZE={5}
          colFilter={true}
          compiler={{ use: true, tool: reportDataCompiler }}
        />
      ) : (
        <h1>No data available, Please search..!!</h1>
      )}
    </>
  );
};
