import React, { useEffect, useState } from 'react';
import { BATCH_COLUMN, BUTTON, TEXT, STATUS, POPUP_TYPE, POPUP_MSG } from '../../../../constants';
import { MfgTable } from './MfgTable';
import { Button, Image } from '../../../shared';
import { productImage } from '../../../../service';
import { popup } from '../../../../utils';

export const BatchDetails = ({
  setField,
  lot,
  setSelectedBatch,
  editBoxState,
  setEditBoxState,
}) => {
  const [image, setImage] = useState('');
  const batchCreator = () => {
    setEditBoxState(1);
  };

  const editHandler = ({ row, e }) => {
    e.stopPropagation();
    setSelectedBatch(row);
    if (row.pack_qty && row.pack_date) {
      setField(STATUS.READY);
      setEditBoxState(2);
    } else if (row.sew_qty && lot.dyeing) {
      setField(STATUS.READY);
      setEditBoxState(2);
    } else if (row.sew_qty) {
      setField(STATUS.PACK);
      setEditBoxState(2);
    } else if (row.cut_qty) {
      setField(STATUS.SEW);
      setEditBoxState(2);
    } else {
      setField(STATUS.CUT);
      setEditBoxState(2);
    }
  };

  useEffect(() => {
    if (lot?.product_id && !image) {
      productImage({ data: { product_id: lot.product_id } })
        .then((response) => {
          if (response.data.length) {
            setImage(response.data[0].image_1920);
          } else {
            popup(POPUP_TYPE.ERR, POPUP_MSG.IMAGE_NOT_FOUND);
          }
        })
        .catch((error) => popup(POPUP_TYPE.ERR, error.message));
    }
  }, [lot]);
  return (
    <div className="w-100 row info-detail-container">
      <div className="col-2">
        <Image src={`data:image/png;base64,${image}`} alt={''} className="img-fluid w-100" />
      </div>
      <div className="col-10">
        <div className="table-container-2 table-2 position-relative mb-3 w-100">
          {lot?.batch?.length > 0 ? (
            <MfgTable
              column={BATCH_COLUMN}
              data={lot.batch}
              dyeing={lot.dyeing}
              clickHandler={editHandler}
            />
          ) : (
            <p className="no-batch">
              {lot.material ? TEXT.NO_BATCH_AVAILABLE : TEXT.MATERIAL_NOT_AVAILABLE}
            </p>
          )}
        </div>
        <div className="col-offset-2 col-10 d-flex align-items-center justify-content-start w-100">
          {lot.ready_qty < lot.total_qty &&
            lot.batch_qty < lot.total_qty &&
            lot.material &&
            editBoxState !== 1 && (
              <Button
                clickHandler={batchCreator}
                className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
                {BUTTON.CREATE_BATCH}
              </Button>
            )}
        </div>
      </div>
    </div>
  );
};
