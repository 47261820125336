import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setLoaderVisibility } from '../../../../redux';
import { SearchBar, Table, TitleBar, DropDown } from '../../../common';
import { PRODUCTION_COLUMN, ROUTES, TITLES, POPUP_TYPE, BUTTON, LOCAL_STORAGE } from '../../../../constants';
import { Button } from '../../../shared';
import { vendorManufacturing } from '../../../../service';
import { useDebounce } from '../../../../hooks';
import { dispatchDataCompiler, popup, getSortingArray, getLocalStorageItem } from '../../../../utils';
export const ProductionContent = () => {
  const dispatch = useDispatch();
  const [ProductionData, setProductionData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState(false);
  const [sort1, setSort1] = useState(PRODUCTION_COLUMN[8].name);
  const [sortingArray1, setSortingArray1] = useState([]);

  const column = PRODUCTION_COLUMN.filter((col) => col.name !== 'Priority');

  const getDispatchList = useDebounce((data = {}) => {
    vendorManufacturing({
      data,
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response.data.length > 0) {
          setProductionData(response.data);
          if (!sortingArray1.includes(sort1)) {
            setSortingArray1(
              getSortingArray({
                column: PRODUCTION_COLUMN[8],
                data: response.data,
                compiler: dispatchDataCompiler,
              }),
            );
          }
        } else {
          setProductionData(response.message);
        }
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getDispatchList();
  }, []);

  const resetFilter = () => {
    setSort1(PRODUCTION_COLUMN[8].name);
    dispatch(setLoaderVisibility(true));
    getDispatchList();
  };

  //  to search data on button press.
  useEffect(() => {
    let data = {};
    if (search && searchTerm) {
      data = {
        name: searchTerm,
        origin: searchTerm,
        lot_name: searchTerm,
        search: 'True',
      };
      setSearch(false);
      dispatch(setLoaderVisibility(true));
      getDispatchList(data);
    } else if (!searchTerm && !Array.isArray(ProductionData)) {
      setSearch(false);
      dispatch(setLoaderVisibility(true));
      getDispatchList(data);
    }
  }, [search, searchTerm]);

  useEffect(() => {
    let data = {};
    if (sortingArray1.length > 1) {
      dispatch(setLoaderVisibility(true));
      data = {
        state: `${sortingArray1.includes(sort1) ? sort1.toLowerCase() : ''}`,
      };
      getDispatchList(data);
    }
  }, [sort1]);

  useEffect(() => {
    if (!searchTerm) {
      dispatch(setLoaderVisibility(true));
      getDispatchList();
    }
  }, [searchTerm]);
  return (
    <>
      <TitleBar title={[{ text: TITLES.MANUFACTURING, route: ROUTES.MANUFACTURING }]} />
      <div className="d-flex align-items-center justify-content-start gap-3 w-100">
        <SearchBar setSearchTerm={setSearchTerm} searchTerm={searchTerm} setSearch={setSearch} />
        <div className="w-50  mb-3 filter-btns-div d-flex align-items-center justify-content-start">
          <DropDown dropdownMenu={sortingArray1} value={sort1} setValue={setSort1}></DropDown>
          {sortingArray1.includes(sort1) && (
            <Button
              clickHandler={resetFilter}
              className="blue-btn d-flex align-items-center justify-content-center px-3">
              {BUTTON.CLEAR_FILTERS}
            </Button>
          )}
        </div>
      </div>
      {Array.isArray(ProductionData) ? (
        <Table
          columns={getLocalStorageItem(LOCAL_STORAGE.VENDOR_TYPE)==='fabric'?PRODUCTION_COLUMN: column}
          initialData={ProductionData}
          PAGE_SIZE={10}
          colFilter={true}
          to={ROUTES.MANUFACTURING}
          compiler={{ use: true, tool: dispatchDataCompiler }}
        />
      ) : (
        <div>
          <h5 className="px-1 py-5">{ProductionData}</h5>
        </div>
      )}
    </>
  );
};
