import React, { useEffect, useRef, useState } from 'react';
import { DetailBox, Table, TitleBar } from '../../common';
import {
  TITLES,
  ROUTES,
  FABRIC_DISPATCH_INNER_COLUMN,
  BUTTON,
  POPUP_TYPE,
  POPUP_MSG,
  LOCAL_STORAGE,
} from '../../../constants';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  dispatchDetailsBox,
  fabricDispatchProductCompiler,
} from '../../../utils/dataCompiler/dispatchDataCompiler';
import { useDispatch } from 'react-redux';
import { useDebounce } from '../../../hooks';
import { updateReceiptQty, vendorReceipt } from '../../../service/receipt';
import { setLoaderVisibility } from '../../../redux';
import { getLocalStorageItem, popup } from '../../../utils';
import { Button, Input, PopUpBox } from '../../shared';
import { markDispatched, vendorUpdateContainerNo } from '../../../service';

export const FabricDispatchDetail = () => {
  const { state } = useLocation(location?.state);
  const navigate = useNavigate();
  const [popUp, setPopUp] = useState(false);
  const [popUpData, setPopUpData] = useState('');
  const [productData, setProductData] = useState([]);
  const [reload, setReload] = useState(false);
  const [containerNo, setContainerNo] = useState('');

  const dispatch = useDispatch();

  let filteredCol =
  getLocalStorageItem(LOCAL_STORAGE.VENDOR_TYPE)!=='fabric'
      ? FABRIC_DISPATCH_INNER_COLUMN.filter((col) => col.name !== 'Rolls')
      : FABRIC_DISPATCH_INNER_COLUMN;
  let filteredColumn =
    state?.dispatch_status === 'dispatched' || state?.state === 'done'
      ? filteredCol.filter((col) => col.name !== 'Action')
      : filteredCol;

  const getProductsList = useDebounce(() => {
    setReload(false);
    vendorReceipt({
      data: { receipt_ids: state.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        setProductData(fabricDispatchProductCompiler(response.data[0]));
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup('error', error.message);
      });
  }, 1000);

  useEffect(() => {
    if (productData?.length !== 0 && productData?.[0]?.container !== false) {
      setContainerNo(productData?.[0]?.container);
    }
  }, [productData]);

  useEffect(() => {
    if (!state) navigate(ROUTES.FABRIC_DISPATCH_DETAIL);

    dispatch(setLoaderVisibility(true));
    getProductsList();
  }, [reload]);

  const updateReceipt = (data) => {
    dispatch(setLoaderVisibility(true));
    updateReceiptQty({ data: { ...data } })
      .then((response) => {
        popup(POPUP_TYPE.SUCCESS, response.message);
        getProductsList();
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  };

  const handleContainerNoChange = (event) => {
    const value = event.target.value;
    setContainerNo(value === '' ? '' : value.trimStart());
  };

  const handleContainerUpdate = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    vendorUpdateContainerNo({ data: { dispatch_id: [state.id], container_id: containerNo } })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup('success', response.message);
        setReload(true);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const submitDispatch = () => {
    dispatch(setLoaderVisibility(true));
    markDispatched({
      data: { dispatch_id: state?.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.SUCCESS, response.message);
        navigate(ROUTES.DISPATCH);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup('error', error.message);
      });
  };

  const enableDispatch = () => {
    if (productData?.length) {
      return productData?.every((move) => move?.lotno !== undefined || move.lotno !== false);
    }
  };

  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.DISPATCH, route: ROUTES.DISPATCH },
          { text: state?.name, route: ROUTES.NULL },
        ]}
      />
      <div className="scroll-div">
        {state?.dispatch_status === 'draft' && (
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center justify-content-start mb-3">
              <label className="container-label">Container No</label>
              <Input
                type="text"
                placeholder="Container No"
                className={`d-flex ${containerNo === '' ? 'disabled' : ''} me-2 search-placeholder align-items-center justify-content-center container-input`}
                value={containerNo}
                onChange={handleContainerNoChange}
                readOnly={
                  !(
                    state?.dispatch_status === 'draft' &&
                    (state?.state !== 'done' || state?.state !== 'cancel')
                  )
                }
              />
              {state?.dispatch_status === 'draft' &&
                (state?.state !== 'done' || state?.state !== 'cancel') && (
                  <Button
                    className={`search-btn ${containerNo === false || containerNo?.length === 0 ? 'disabled' : ''} d-flex align-items-center justify-content-center px-3`}
                    clickHandler={handleContainerUpdate}
                    disabled={containerNo === false || containerNo === ''}>
                    {BUTTON.SAVE}
                  </Button>
                )}
            </div>
            <Button
              disabled={
                !enableDispatch() ||
                productData?.[0]?.container === false ||
                productData?.[0]?.container === ''
              }
              className={`blue-btn d-flex align-items-center ${enableDispatch() ? '' : 'disabled'} ${productData?.[0]?.container === false || productData?.[0]?.container === '' ? 'disabled' : ''} justify-content-center px-4 mb-3`}
              clickHandler={submitDispatch}>
              {BUTTON.DISPATCH}
            </Button>
          </div>
        )}
        <DetailBox colCount={2} colData={state} dataCompiler={dispatchDetailsBox} />
        <Table
          columns={filteredColumn}
          initialData={productData}
          PAGE_SIZE={4}
          colFilter={false}
          popUp={popUp}
          setPopUp={setPopUp}
          setPopUpData={setPopUpData}
        />
      </div>
      {popUp && (
        <PopUpBox openPopUpBox={popUp}>
          <QtyUpdate apiCall={updateReceipt} setOpenPopUpBox={setPopUp} data={popUpData} />
        </PopUpBox>
      )}
    </>
  );
};

const QtyUpdate = ({ apiCall, setOpenPopUpBox, data }) => {
  const [doneQty, setDoneQty] = useState('');
  const qtyRef = useRef();
  const closePopUp = () => {
    setOpenPopUpBox(false);
  };
  const handleApiCall = () => {
    if (doneQty > 0) {
      setOpenPopUpBox(false);
      const apiData = {
        move_id: data.move_id,
        done_qty: +doneQty,
      };
      apiCall(apiData);
    } else {
      popup(POPUP_TYPE.ERR, POPUP_MSG.INVALID_QTY);
    }
  };

  useEffect(() => {
    qtyRef.current.focus();
  }, []);

  return (
    <>
      <div className="d-flex flex-column m-1 ">
        <h4 className=" text-center text-special mb-3 mt-3"> {POPUP_MSG.ENTER_QTY} </h4>
        <hr />
        <div className="w-100 modal-body py-0 d-flex flex-column  gap-3 justify-content-center align-items-center">
          <div className="d-flex justify-content-between w-100 ">
            <label className="ms-3">Quantity</label>
            <input
              type="number"
              className="ps-2 curve-border me-4"
              placeholder={data.producing_quantity || ''}
              ref={qtyRef}
              onChange={(e) => {
                setDoneQty(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <hr />
      <div className="pb-3 mt-2 d-flex align-items-center justify-content-end mx-4  modal-btn custom-button">
        <div className="ms-5 ps-5 w-50 d-flex align-items-center justify-content-between">
          <Button
            clickHandler={handleApiCall}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.UPDATE}
          </Button>
          <Button
            clickHandler={closePopUp}
            className="border-0 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CLOSE}
          </Button>
        </div>
      </div>
    </>
  );
};
