import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from '../shared';
// import {formatDateToString} from '../../utils/misc';

export const DatePickerBox = ({value, setValue, clickHandler, showSave, btnName }) => {
  return (
    <>
      <DatePicker
        selected={value}
        onChange={(date) => setValue(date)}
        className="bill-date-picker "
        dateFormat="yyyy-MM-dd"
        showYearDropdown
        showMonthDropdown
        scrollableYearDropdown
        placeholderText="YYYY-MM-DD"
        onKeyDown={(e) => {e.preventDefault()}}
      />
      <Button
        className={`border-0 blue-btn d-flex align-items-center ${!showSave ? 'disabled' : ''} justify-content-center py-2 px-3`}
        clickHandler={clickHandler}
        disabled={!showSave}>
        {btnName}
      </Button>
    </>
  );
};

export default DatePicker;
