import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { SORTING } from '../../constants';

export const TableBody = ({
  columns,
  data,
  colFilter,
  to,
  compiler,
  popUp,
  setPopUp,
  setPopUpData,
  sortInfo = {},
  handleSort,
  func
}) => {
  const navigate = useNavigate();
  const [finalData, setFinalData] = useState([]);

  const [sortedData, setSortedData] = useState([]);
  const handleRowClick = ({ data, url }) => {
    if (to) {
      navigate(`${to}/${url}`, { state: data });
    }
  };

  const filteredColumns = colFilter
    ? columns
    : columns.filter((column) => column.name !== 'checkbox');
  useEffect(() => {
    setFinalData(compiler?.use ? compiler.tool(data) : data);
  }, [data]);

  // to populate sortData with data
  useEffect(() => {
    setSortedData(finalData);
  }, [finalData]);

  // to sort data if sortInfo changes
  useEffect(() => {
    if (sortInfo.sortType !== SORTING.DEFAULT) {
      const result = handleSort({ sortInfo, data:sortedData });
      setSortedData(result);
    }
  }, [sortInfo]);
  return (
    <tbody>
      {sortedData.map((row, rowIndex) => (
        <tr
          key={rowIndex}
          className="table-row"
          style={{ cursor: to ? 'pointer' : 'default' }}
          onClick={to ? () => handleRowClick({ data: row.data, url: row.url }) : undefined}>
          {filteredColumns.map((column, colIndex) => {
            return (
              <td key={colIndex} className={column.classNameTD}>
                {column.isCustom ? (
                  column.customFormatter(
                    column.dataField.reduce((acc, field) => {
                      acc[field] = row[field];
                      return { ...acc, popUp, setPopUp, setPopUpData,func };
                    }, {}),
                  )
                ) : (
                  <div
                    className={`${typeof row[column.dataField] === 'number' ? 'td-num' : 'td-text'}`}>
                    {row[column.dataField]}
                  </div>
                )}
              </td>
            );
          })}
        </tr>
      ))}
    </tbody>
  );
};

TableBody.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  colFilter: PropTypes.bool,
  to: PropTypes.string,
};
