import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setLoaderVisibility } from '../../../redux';
import { SearchBar, Table, TitleBar, DropDown } from '../../common';
import { BILLS_COLUMN, ROUTES, TITLES, POPUP_TYPE, BUTTON } from '../../../constants';
import { Button } from '../../shared';
import { vendorBill } from '../../../service';
import { useDebounce } from '../../../hooks';
import { billDataCompiler, popup, getSortingArray } from '../../../utils';
export const BillsContent = () => {
  // using hooks
  const dispatch = useDispatch();
  const [billsData, setBillsData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState(false);
  const [sort1, setSort1] = useState(BILLS_COLUMN[6].name);
  const [sortingArray1, setSortingArray1] = useState([]);

  // API Calling
  const getBillsList = useDebounce((data = {}) => {
    vendorBill({
      data,
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response.data.length > 0) {
          setBillsData(response.data);
          if (!sortingArray1.includes(sort1)) {
            setSortingArray1(
              getSortingArray({
                column: BILLS_COLUMN[6],
                data: response.data,
                compiler: billDataCompiler,
              }),
            );
          }
        } else setBillsData(response.message);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  //  to search data on button press.
  useEffect(() => {
    let data = {};
    if (search && searchTerm) {
      data = {
        name: searchTerm,
        invoice_origin: searchTerm,
        search: 'True',
      };
      setSearch(false);
      dispatch(setLoaderVisibility(true));
      getBillsList(data);
    } else if (!searchTerm && !Array.isArray(billsData)) {
      setSearch(false);
      dispatch(setLoaderVisibility(true));
      getBillsList(data);
    }
  }, [search, searchTerm]);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getBillsList();
  }, []);

  useEffect(() => {
    let data = {};
    
    if (sortingArray1.includes(sort1) && sortingArray1.length > 1) {
      data = {
        state: `${sort1?.toLowerCase()}`,
      };
      dispatch(setLoaderVisibility(true));
      getBillsList(data);
    }
  }, [sort1]);

  const resetFilter = () => {
    setSort1(BILLS_COLUMN[6].name);
    dispatch(setLoaderVisibility(true));
    getBillsList();
  };

  useEffect(() => {
    if(!searchTerm){
      dispatch(setLoaderVisibility(true));
      getBillsList();
    }
  }, [searchTerm]);
  return (
    <>
      <TitleBar title={[{ text: TITLES.BILLS, route: ROUTES.BILLS }]} />
      <div className="d-flex align-items-center justify-content-start gap-3 w-100">
        <SearchBar setSearchTerm={setSearchTerm} searchTerm={searchTerm} setSearch={setSearch} />
        <div className="w-50  mb-3 filter-btns-div d-flex align-items-center justify-content-start">
          <DropDown dropdownMenu={sortingArray1} value={sort1} setValue={setSort1}></DropDown>
          {sortingArray1.includes(sort1) && (
            <Button
              clickHandler={resetFilter}
              className="blue-btn d-flex align-items-center justify-content-center px-3">
              {BUTTON.CLEAR_FILTERS}
            </Button>
          )}
        </div>
      </div>
      {Array.isArray(billsData) ? (
        <Table
          columns={BILLS_COLUMN}
          initialData={billsData}
          PAGE_SIZE={10}
          colFilter={true}
          to={ROUTES.BILLS}
          compiler={{ use: true, tool: billDataCompiler }}
        />
      ) : (
        <div>
          <h5 className="px-1 py-5">{billsData}</h5>
        </div>
      )}
    </>
  );
};
