// import { getReadyQty } from '../../components/pages/manufacturing/ManufacturingContent';

export const manufacturingDataCompiler = (data) => {
  let finalData = [];
  data.map((obj) => {
    let mfgData = {
      url: obj.future_lot,
      lot_no: obj.future_lot,
      SKU: obj.product_id.default_code || obj.product_id.display_name,
      total_qty: obj.product_uom_qty,
      priority: obj.manufacture_priority,
      batch: obj.batch_ids,
      id: obj.id,
      product_id: obj.product_id.id,
      display_name: obj.product_id.display_name,
      ready_qty: obj.produced_qty,
      qty_status:
        obj.produced_qty === 0
          ? 'Pending'
          : obj.produced_qty < obj.product_uom_qty
            ? 'Partial'
            : 'Completed',
    };
    finalData.push(mfgData);
  });
  return finalData;
};

export function fabricManufacturingProductCompiler(data = []) {
  let finalData = [];
  const vendor_shipment = data.vendor_shipment;
  data.move_ids.map((obj) => {
    let dispatchProductsData = {
      move_id: obj.id,
      rolls: obj.no_of_rolls,
      vendor_shipment,
      subcontract: obj.subcontract_has_been_recorded,
      material:obj.material_available,
      lotno: vendor_shipment ? obj.move_line_ids[0].lot_id.name : obj.move_line_ids[0].lot_name,
      demand_quantity: obj.product_uom_qty,
      producing_quantity: obj.quantity,
      tracking: obj?.product_id?.tracking,
      unit: obj.product_uom?.name,
      product: obj.product_id?.display_name,
      data: obj,
      edit: obj.subcontract_has_been_recorded,
    };
    finalData.push(dispatchProductsData);
  });
  return finalData;
}

export const batchDataCompiler = (obj) => {
  let finalData = {
    product_id: obj.product_id.id,
    batch: obj.batch_ids,
    lotData: obj,
    batch_qty: obj?.batch_ids?.reduce((acc, cum) => (acc += cum.order_qty), 0),
    material:
      obj.move_ids.filter((move) => move.state === 'assigned')[0]?.material_available ||
      obj.move_ids[0].material_available,
    SKU: obj.product_id.default_code,
    lot_no: obj.future_lot,
    total_qty: obj.product_uom_qty,
    ready_qty: obj.produced_qty,
    move_id: obj.move_ids.filter((move) => move.state === 'assigned')[0]?.id || obj.move_ids[0].id,
    dyeing: obj.product_id.dyeing_type === 'dyeing',
    priority:obj.manufacture_priority
  };
  return finalData;
};
