import React, { useEffect, useState } from 'react';
import { SearchBar, TitleBar } from '../../common';
import {
  BUTTON,
  DISPLAY_TEXTS,
  PKJ_STATUS,
  POPUP_MSG,
  POPUP_TYPE,
  ROUTES,
  TITLES,
} from '../../../constants';
import { Button, Input, PopUpBox } from '../../shared';
import { useLocation, useNavigate } from 'react-router-dom';
import { StatusBox } from '../manufacturing/clothManufacturing/StatusBox';
import { Scanner } from '../../common/Scanner';
import { useDebounce } from '../../../hooks';
import { setLoaderVisibility } from '../../../redux';
import { popup } from '../../../utils';
import {
  getPreScannedPackages,
  vendorDeleteDispatch,
  vendorSubmitDispatch,
  addPackageToDispatch,
  vendorUpdateContainerNo,
} from '../../../service';
import { useDispatch } from 'react-redux';
import { shipmentDataCompiler } from '../../../utils/dataCompiler/dispatchDataCompiler';
import { FabricDispatchDetail } from './FabricDispatchDetail';
import { BlpTilesDispatch } from '../../common/BlpTilesDispatch';

export const DispatchDetail = () => {
  const { state } = useLocation(location?.state);
  return <>{state?.dyeing_shipment ? <FabricDispatchDetail /> : <DispatchDetailNonDyeing />}</>;
};

const DispatchDetailNonDyeing = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tilesToDisplay, setTilesToDisplay] = useState([]);
  const [preScannedPackages, setPreScannedPackages] = useState([]);
  const [reload, setReload] = useState(false);
  const [scanData, setScanData] = useState('');
  const [scanning, setScanning] = useState(false);
  const [validate, setValidate] = useState(false);
  const { state } = useLocation(location?.state);
  const [containerNo, setContainerNo] = useState(
    state?.container_id !== false ? state?.container_id : 'N/A',
  );

  const [isCamScannerOpen, setIsCamScannerOpen] = useState(true);

  const isCameraScan = localStorage.getItem('isCameraScan');
  const isCameraScanBool = isCameraScan === 'true';

  const deleteDispatch = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    vendorDeleteDispatch({
      data: { dispatch_id: state?.id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup('success', response.message);
        navigate(ROUTES.DISPATCH);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const handleContainerNoChange = (event) => {
    const value = event.target.value;
    setContainerNo(value === '' ? '' : value.trimStart());
  };

  const handleContainerUpdate = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    vendorUpdateContainerNo({ data: { dispatch_id: [state.id], container_id: containerNo } })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup('success', response.message);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const getAlreadyScannedPackages = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    getPreScannedPackages({
      data: { id: state?.id },
    })
      .then((response) => {
        response.data.length > 0
          ? setPreScannedPackages(shipmentDataCompiler(response.data[0]))
          : setPreScannedPackages(response.message);
        setReload(false);
        dispatch(setLoaderVisibility(false));
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const startScanning = () => {
    setScanning((pre) => !pre);
    isCameraScanBool ? setIsCamScannerOpen(true) : setIsCamScannerOpen(false);
  };

  useEffect(() => {
    if (!state) navigate(ROUTES.DISPATCH_DETAILS);

    isCameraScanBool && !isCamScannerOpen ? setScanning(false) : '';
  });

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getAlreadyScannedPackages();
  }, []);

  useEffect(() => {
    if (reload) {
      dispatch(setLoaderVisibility(true));
      getAlreadyScannedPackages();
      setReload(!reload);
    }
  }, [reload]);

  useEffect(() => {
    if (Array.isArray(preScannedPackages)) {
      const initialTiles = preScannedPackages.map((tile) => {
        const isUniformSKU = tile?.SKU_Details?.every(
          (sku) => sku?.SKU === tile?.SKU_Details[0]?.SKU,
        );
        const tileClass = isUniformSKU ? 'blue-shade' : 'pink-shade';
        return { ...tile, tileClass };
      });
      setTilesToDisplay(initialTiles);
    }
  }, [preScannedPackages]);

  useEffect(() => {
    if (scanData) {
      dispatch(setLoaderVisibility(true));
      addPackageToDispatch({
        data: {
          dispatch_id: state?.id,
          package_name: scanData,
          type: 'add',
        },
      }).then((response) => {
        if (response.data.length > 0) popup(POPUP_TYPE.SUCCESS, response.message);
        else if (response.message.includes('flagged'))
          popup(POPUP_TYPE.SUCCESS, `Duplicate Package ${scanData} is Flagged`);
        else popup(POPUP_TYPE.ERR, response.message);
      });
      setScanData('');
      dispatch(setLoaderVisibility(false));
      getAlreadyScannedPackages();
    }
  }, [scanData]);
  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.DISPATCH, route: ROUTES.DISPATCH },
          { text: state?.name, route: ROUTES.NULL },
        ]}
      />
      <div className="bg-white p-2 align-items-center package-top-info border-def-up border-def-down">
        <div className="d-flex align-items-center justify-content-end">
          <StatusBox status={PKJ_STATUS} />
        </div>
        <div className="d-flex align-items-center justify-content-between mb-3 top-header-set">
          <SearchBar />
          <div className="d-flex justify-content-end align-items-center">
            <h4 className="me-3 mb-4">{DISPLAY_TEXTS.CREATE_DISPATCH[1]}</h4>
            <div className="number-count mb-4 d-flex align-items-center justify-content-center">
              {tilesToDisplay.length}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-start mb-3">
          <label className="container-label">Container No</label>
          <Input
            type="text"
            placeholder="Container No"
            className={`d-flex ${containerNo === '' ? 'disabled' : ''} me-2 search-placeholder align-items-center justify-content-center container-input`}
            value={containerNo}
            onChange={handleContainerNoChange}
            readOnly={
              !(
                state?.dispatch_status === 'draft' &&
                (state?.state !== 'done' || state?.state !== 'cancel')
              )
            }
          />
          {state?.dispatch_status === 'draft' &&
            (state?.state !== 'done' || state?.state !== 'cancel') && (
              <Button
                className={`search-btn ${containerNo === '' ? 'disabled' : ''} d-flex align-items-center justify-content-center px-3`}
                clickHandler={handleContainerUpdate}
                disabled={containerNo === ''}>
                {state?.container_id !== false ? BUTTON.UPDATE : BUTTON.SAVE}
              </Button>
            )}
        </div>

        {state?.state === 'draft' && (
          <div className="d-flex justify-content-between">
            <Button
              clickHandler={deleteDispatch}
              className="blue-btn d-flex align-items-center justify-content-center px-4 mx-2">
              {BUTTON.CANCEL_DISPATCH}
            </Button>
            <div className="d-flex justify-content-end">
              {scanning && (
                <Scanner
                  setScanData={setScanData}
                  isCamScannerOpen={isCamScannerOpen}
                  setIsCamScannerOpen={setIsCamScannerOpen}
                />
              )}
              <Button
                clickHandler={startScanning}
                className="white-btn d-flex align-items-center justify-content-center px-4 mx-2">
                {scanning ? BUTTON.STOP_SCAN : BUTTON.SCAN_BOX}
              </Button>
              <Button
                className={`blue-btn d-flex align-items-center ${preScannedPackages?.length + tilesToDisplay?.length === 0 ? 'disabled' : ''} justify-content-center px-4`}
                clickHandler={() => setValidate(true)}
                disabled={preScannedPackages?.length + tilesToDisplay?.length === 0}>
                {BUTTON.SUBMIT}
              </Button>
            </div>
          </div>
        )}
      </div>
      <div className="bg-white p-3 pt-0 mt-2 package-titles-info border-def-up border-def-down">
        <div className="row custom-row">
          {tilesToDisplay.map((tile, index) => (
            <BlpTilesDispatch
              key={index}
              tile={tile}
              dispatch_id={state?.id}
              dispatch_status={state?.dispatch_status}
              showLoader={scanning}
              setReload={setReload}
            />
          ))}
        </div>
      </div>
      {validate && (
        <PopUpBox openPopUpBox={validate}>
          <DispatchSubmitValidate setOpenPopUpBox={setValidate} id={state?.id} containerNo={containerNo} />
        </PopUpBox>
      )}
    </>
  );
};

const DispatchSubmitValidate = ({ setOpenPopUpBox, id, containerNo }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const closePopUp = () => {
    setOpenPopUpBox(false);
  };

  const handleSubmitDispatch = () => {
    setOpenPopUpBox(false);
    dispatch(setLoaderVisibility(true));
    vendorSubmitDispatch({
      data: { dispatch_id: id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup('success', response.message);
        navigate(ROUTES.DISPATCH);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  };

  const handleSaveAsDraft = () => {
    popup('success', 'Dispatch Saved as Draft');
    navigate(ROUTES.DISPATCH);
  };

  return (
    <>
      <div className="d-flex m-1">
        <div className="w-25 modal-body py-0 d-flex justify-content-center align-items-center">
          <span className="d-flex justify-content-center align-items-center bg-info badge">!</span>
        </div>
        <div className="w-75 modal-body py-0">
          <h4 className="text-center text-special mb-3">
            <u>NOTE</u>
          </h4>
          <p className="text-center text-special placeholder-color">
            {POPUP_MSG.SUBMIT_DISPATCH_WARNING}
          </p>
        </div>
      </div>
      <hr />
      <div className="pb-3 mt-2 d-flex align-items-center justify-content-end mx-4 modal-btn custom-button">
        <div className="d-flex align-items-center justify-content-between">
          <Button
            clickHandler={handleSubmitDispatch}
            className={`border-0 m-1 ${containerNo==='' || containerNo===false || containerNo==='N/A'?'disabled':''} blue-btn d-flex align-items-center justify-content-center py-2 px-3`} disabled={containerNo==='' || containerNo===false || containerNo==='N/A'}>
            {BUTTON.SUBMIT}
          </Button>
          <Button
            clickHandler={handleSaveAsDraft}
            className="border-0 m-1 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.SAVE_AS_DRAFT}
          </Button>
          <Button
            clickHandler={closePopUp}
            className="border-0 m-1 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CLOSE}
          </Button>
        </div>
      </div>
    </>
  );
};
