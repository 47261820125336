import React, { useEffect, useState } from 'react';
import { SearchBar, Table, TitleBar, DropDown } from '../../common';
import { BUTTON, DISPATCH_COLUMN, ROUTES, TITLES, POPUP_TYPE } from '../../../constants';
import { dispatchDataCompiler } from '../../../utils/dataCompiler/dispatchDataCompiler';
import { useDispatch } from 'react-redux';
import { setLoaderVisibility } from '../../../redux';
import { vendorCreateDispatch, vendorDispatch } from '../../../service';
import { useDebounce } from '../../../hooks';
import { getLocalStorageItem, popup, getSortingArray } from '../../../utils';
import { Button } from '../../shared';
import { useNavigate } from 'react-router-dom';

export const DispatchContent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dispatchData, setDispatchData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [search, setSearch] = useState(false);
  const [sort1, setSort1] = useState(DISPATCH_COLUMN[8].name);
  const [sort2, setSort2] = useState(DISPATCH_COLUMN[9].name);
  const [sortingArray1, setSortingArray1] = useState([]);
  const [sortingArray2, setSortingArray2] = useState([]);
  const createDispatch = useState([]);

  const getCreateDispatch = useDebounce(() => {
    vendorCreateDispatch({
      data: {},
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        createDispatch[1](response.data[0]);
        navigate(ROUTES.CREATE_DISPATCH, { state: response.data[0] });
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const handleCreateDispatch = () => {
    dispatch(setLoaderVisibility(true));
    getCreateDispatch();
  };

  const getDispatchList = useDebounce((data = {}) => {
    vendorDispatch({
      data,
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response.data.length > 0) {
          setDispatchData(response.data);
          if (!sortingArray1.includes(sort1) && !sortingArray2.includes(sort2)) {
            setSortingArray1(
              getSortingArray({
                column: DISPATCH_COLUMN[8],
                data: response.data,
                compiler: dispatchDataCompiler,
              }),
            );
            setSortingArray2(
              getSortingArray({
                column: DISPATCH_COLUMN[9],
                data: response.data,
                compiler: dispatchDataCompiler,
              }),
            );
          }
        } else {
          setDispatchData(response.message);
        }
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getDispatchList();
  }, []);

  const tags = getLocalStorageItem('tags');
  let destinationRoute;
  if (tags[0] === 'clothing') {
    destinationRoute = ROUTES.DISPATCH_DETAILS;
  } else if (tags[0] === 'fabric') {
    destinationRoute = ROUTES.FABRIC_DISPATCH_DETAIL;
  } else {
    destinationRoute = ROUTES.YARN_DISPATCH_DETAIL;
  }

  const resetFilter = () => {
    setSort1(DISPATCH_COLUMN[8].name);
    setSort2(DISPATCH_COLUMN[9].name);
    
    dispatch(setLoaderVisibility(true));
    getDispatchList();
  };

  //  to search data on button press.
  useEffect(() => {
    let data = {};
    if (search && searchTerm) {
      data = {
        name: searchTerm,
        origin: searchTerm,
        lot_name: searchTerm,
        container_id: searchTerm,
        search: 'True',
      };
      setSearch(false);
      dispatch(setLoaderVisibility(true));
      getDispatchList(data);
    } else if (!searchTerm && !Array.isArray(dispatchData)) {
      setSearch(false);
      dispatch(setLoaderVisibility(true));
      getDispatchList(data);
    }
  }, [search, searchTerm]);

  useEffect(() => {
    let data = {};
    dispatch(setLoaderVisibility(true));
    if (sortingArray1.length > 1 || sortingArray2.length > 1) {
      data = {
        ['dispatch_status']: `${sortingArray1.includes(sort1) ? sort1.toLowerCase() : ''}`,
        ['state']: `${sortingArray2.includes(sort2) ? sort2.toLowerCase() : ''}`,
      };
      getDispatchList(data);
    }
  }, [sort1, sort2]);


  useEffect(() => {
    if(!searchTerm){
      dispatch(setLoaderVisibility(true));
      getDispatchList();
    }
  }, [searchTerm]);
  return (
    <>
      <TitleBar title={[{ text: TITLES.DISPATCH, route: ROUTES.DISPATCH }]} />
      <div className="d-flex justify-content-end my-3">
        {getLocalStorageItem('tags')[0] === 'clothing' && (
          <Button
            clickHandler={handleCreateDispatch}
            className="white-btn d-flex align-items-center justify-content-center px-4 mx-2 mt-1">
            {BUTTON.CREATE_DISPATCH}
          </Button>
        )}
      </div>
      <div className="d-flex align-items-center justify-content-start gap-3 w-100">
        <SearchBar setSearchTerm={setSearchTerm} searchTerm={searchTerm} setSearch={setSearch} />
        <div className="w-50  mb-3 filter-btns-div d-flex align-items-center justify-content-start">
          <DropDown dropdownMenu={sortingArray1} value={sort1} setValue={setSort1}></DropDown>
          <DropDown dropdownMenu={sortingArray2} value={sort2} setValue={setSort2}></DropDown>
          {(sortingArray2.includes(sort2) || sortingArray1.includes(sort1)) && (
            <Button
              clickHandler={resetFilter}
              className="blue-btn d-flex align-items-center justify-content-center px-3">
              {BUTTON.CLEAR_FILTERS}
            </Button>
          )}
        </div>
      </div>
      {Array.isArray(dispatchData) ? (
        <Table
          columns={DISPATCH_COLUMN}
          initialData={dispatchData}
          PAGE_SIZE={10}
          colFilter={true}
          to={destinationRoute}
          compiler={{ use: true, tool: dispatchDataCompiler }}
        />
      ) : (
        <div>
          <h5 className="px-1 py-5">{dispatchData}</h5>
        </div>
      )}
    </>
  );
};
