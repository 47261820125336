import React, { useEffect, useState } from 'react';
import { SearchBar, TitleBar } from '../../common';
import {
  BUTTON,
  DISPLAY_TEXTS,
  PKJ_STATUS,
  POPUP_MSG,
  POPUP_TYPE,
  ROUTES,
  TITLES,
} from '../../../constants';
import { Button, Input, PopUpBox } from '../../shared';
import { useLocation, useNavigate } from 'react-router-dom';
import { StatusBox } from '../manufacturing/clothManufacturing/StatusBox';
import { Scanner } from '../../common/Scanner';
import { useDebounce } from '../../../hooks';
import { setLoaderVisibility } from '../../../redux';
import { popup } from '../../../utils';
import {
  vendorDeleteDispatch,
  vendorSubmitDispatch,
  getPreScannedPackages,
  addPackageToDispatch,
  vendorUpdateContainerNo,
} from '../../../service';
import { useDispatch } from 'react-redux';
import { shipmentDataCompiler } from '../../../utils/dataCompiler/dispatchDataCompiler';
import { BlpTilesDispatch } from '../../common/BlpTilesDispatch';

export const CreateDispatch = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const [tilesToDisplay, setTilesToDisplay] = useState([]);
  const [scanData, setScanData] = useState('');
  const [scanning, setScanning] = useState(false);
  const [reload, setReload] = useState(false);
  const [validate, setValidate] = useState(false);
  const [preScannedPackages, setPreScannedPackages] = useState([]);
  const [isCamScannerOpen, setIsCamScannerOpen] = useState(true);
  const [containerNo, setContainerNo] = useState(
    state?.container_id !== false ? state?.container_id : '',
  );

  const isCameraScan = localStorage.getItem('isCameraScan');
  const isCameraScanBool = isCameraScan === 'true';

  // Handler for container number input change
  const handleContainerNoChange = (event) => {
    const value = event.target.value;
    setContainerNo(value === '' ? '' : value.trimStart());
  };

  const handleContainerUpdate = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    vendorUpdateContainerNo({ data: { dispatch_id: [state.id], container_id: containerNo } })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup('success', response.message);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const deleteDispatch = useDebounce(() => {
    dispatch(setLoaderVisibility(true));
    vendorDeleteDispatch({ data: { dispatch_id: state.id } })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup('success', response.message);
        navigate(ROUTES.DISPATCH);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  const startScanning = () => {
    setScanning((prev) => !prev);
    setIsCamScannerOpen(isCameraScanBool);
  };

  useEffect(() => {
    if (isCameraScanBool && !isCamScannerOpen) setScanning(false);
  }, [isCamScannerOpen, isCameraScanBool]);

  useEffect(() => {
    dispatch(setLoaderVisibility(true));
    getAlreadyScannedPackages();
  }, []);

  const getAlreadyScannedPackages = useDebounce(() => {
    getPreScannedPackages({ data: { id: state?.id } })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        if (response.data.length > 0) {
          setPreScannedPackages(shipmentDataCompiler(response.data[0]));
        } else {
          setPreScannedPackages(response.message);
        }
        setReload(false);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  }, 1000);

  useEffect(() => {
    if (Array.isArray(preScannedPackages)) {
      const initialTiles = preScannedPackages.map((tile) => {
        const isUniformSKU = tile.SKU_Details.every((sku) => sku.SKU === tile.SKU_Details[0].SKU);
        const tileClass = isUniformSKU ? 'blue-shade' : 'pink-shade';
        return { ...tile, tileClass };
      });
      setTilesToDisplay(initialTiles);
    }
  }, [preScannedPackages]);

  useEffect(() => {
    if (scanData) {
      dispatch(setLoaderVisibility(true));
      addPackageToDispatch({
        data: { dispatch_id: state?.id, package_name: scanData, type: 'add' },
      }).then((response) => {
        popup(POPUP_TYPE.SUCCESS, response.message);
        getAlreadyScannedPackages();
        setScanData('');
      });
    }
  }, [scanData]);

  useEffect(() => {
    if (reload) {
      dispatch(setLoaderVisibility(true));
      getAlreadyScannedPackages();
      setReload(!reload);
    }
  }, [reload]);

  return (
    <>
      <TitleBar
        title={[
          { text: TITLES.DISPATCH, route: ROUTES.DISPATCH },
          { text: TITLES.CREATE_DISPATCH, route: ROUTES.NULL },
        ]}
      />
      <div className="bg-white p-3 align-items-center package-top-info border-def-up border-def-down">
        <div className="d-flex align-items-center justify-content-between">
          <div className="align-items-center justify-content-between mb-3 top-header-set">
            <h4 className="mb-0">
              {DISPLAY_TEXTS.CREATE_DISPATCH[0]}
              <span>{state?.name}</span>
            </h4>
          </div>
          <StatusBox status={PKJ_STATUS} />
        </div>

        <div className="d-flex align-items-center justify-content-between top-header-set">
          <SearchBar />
          <div className="d-flex justify-content-end align-items-center">
            <h4 className="me-3 mb-4">{DISPLAY_TEXTS.CREATE_DISPATCH[1]}</h4>
            <div className="number-count mb-4 d-flex align-items-center justify-content-center">
              {tilesToDisplay.length}
            </div>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-start mb-3">
          <label className="container-label">Container No</label>
          <Input
            type="text"
            placeholder="Container No"
            className="d-flex me-2 search-placeholder align-items-center justify-content-center container-input"
            value={containerNo}
            onChange={handleContainerNoChange}
          />
          <Button
            className={`search-btn ${containerNo === '' ? 'disabled' : ''} d-flex align-items-center justify-content-center px-3`}
            clickHandler={handleContainerUpdate}
            disabled={containerNo === ''}>
            {BUTTON.SAVE}
          </Button>
        </div>

        <div className="d-flex justify-content-between">
          <Button
            clickHandler={deleteDispatch}
            className="blue-btn d-flex align-items-center justify-content-center px-4 mx-2">
            {BUTTON.CANCEL_DISPATCH}
          </Button>
          <div className="d-flex justify-content-end">
            {scanning && (
              <Scanner
                setScanData={setScanData}
                isCamScannerOpen={isCamScannerOpen}
                setIsCamScannerOpen={setIsCamScannerOpen}
              />
            )}
            <Button
              clickHandler={startScanning}
              className="white-btn d-flex align-items-center justify-content-center px-4 mx-2">
              {scanning ? BUTTON.STOP_SCAN : BUTTON.SCAN_BOX}
            </Button>
            <Button
              className={`blue-btn d-flex ${preScannedPackages.length + tilesToDisplay.length === 0 ? 'disabled' : ''} align-items-center justify-content-center px-4`}
              clickHandler={() => setValidate(true)}
              disabled={preScannedPackages.length + tilesToDisplay.length === 0}>
              {BUTTON.SUBMIT}
            </Button>
          </div>
        </div>
      </div>

      <div className="bg-white p-3 pt-0 package-titles-info border">
        <div className="row custom-row">
          {Array.isArray(preScannedPackages)
            ? tilesToDisplay.map((tile, index) => (
                <BlpTilesDispatch
                  key={index}
                  tile={tile}
                  dispatch_id={state?.id}
                  dispatch_status={state?.dispatch_status}
                  showLoader={scanning}
                  setReload={setReload}
                />
              ))
            : preScannedPackages}
        </div>
      </div>

      {validate && (
        <PopUpBox openPopUpBox={validate}>
          <DispatchSubmitValidate
            setOpenPopUpBox={setValidate}
            id={state?.id}
            containerNo={containerNo}
          />
        </PopUpBox>
      )}
    </>
  );
};
const DispatchSubmitValidate = ({ setOpenPopUpBox, id, containerNo }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const closePopUp = () => {
    setOpenPopUpBox(false);
  };

  const handleSubmitDispatch = () => {
    setOpenPopUpBox(false);
    dispatch(setLoaderVisibility(true));
    vendorSubmitDispatch({
      data: { dispatch_id: id },
    })
      .then((response) => {
        dispatch(setLoaderVisibility(false));
        popup('success', response.message);
        navigate(ROUTES.DISPATCH);
      })
      .catch((error) => {
        dispatch(setLoaderVisibility(false));
        popup(POPUP_TYPE.ERR, error.message);
      });
  };

  const handleSaveAsDraft = () => {
    popup('success', 'Dispatch Saved as Draft');
    navigate(ROUTES.DISPATCH);
  };

  return (
    <>
      <div className="d-flex m-1">
        <div className="w-25 modal-body py-0 d-flex justify-content-center align-items-center">
          <span className="d-flex justify-content-center align-items-center bg-info badge">!</span>
        </div>
        <div className="w-75 modal-body py-0">
          <h4 className="text-center text-special mb-3">
            <u>NOTE</u>
          </h4>
          <p className="text-center text-special placeholder-color">
            {POPUP_MSG.SUBMIT_DISPATCH_WARNING}
          </p>
        </div>
      </div>
      <hr />
      <div className="pb-3 mt-2 d-flex align-items-center justify-content-end mx-4 modal-btn custom-button">
        <div className="d-flex align-items-center justify-content-between">
          <Button
            clickHandler={handleSubmitDispatch}
            className={`border-0 m-1 ${containerNo === '' || containerNo === false || containerNo === 'N/A' ? 'disabled' : ''} blue-btn d-flex align-items-center justify-content-center py-2 px-3`}
            disabled={containerNo === '' || containerNo === false || containerNo === 'N/A'}>
            {BUTTON.SUBMIT}
          </Button>
          <Button
            clickHandler={handleSaveAsDraft}
            className="border-0 m-1 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.SAVE_AS_DRAFT}
          </Button>
          <Button
            clickHandler={closePopUp}
            className="border-0 m-1 blue-btn d-flex align-items-center justify-content-center py-2 px-3">
            {BUTTON.CLOSE}
          </Button>
        </div>
      </div>
    </>
  );
};
